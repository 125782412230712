import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `Capta más propiedades para vender | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content: `Capta más propiedades para vender | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `${companyConfig.name} te ayuda a caputrar más propiedades para vender o rentar. Con nuestra plataforma recives mandatos en forma continua y gratuita. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const AcquireMorePropertiesSEO = () => (
  <HeadSEO
    canonical="/capta-propiedades"
    title={`Capta más propiedades para vender | ${companyConfig.name}`}
    description={`${companyConfig.name} te ayuda a caputrar más propiedades para vender o rentar. Con nuestra plataforma recives mandatos en forma continua y gratuita. ${companyConfig.name}, alianza inmobiliaria.`}
    tags={SEO_TAGS}
  />
)

export default AcquireMorePropertiesSEO
