import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Vende o renta con ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `${companyConfig.name} es la primera inmobiliaria digital de México, ofrecemos beneficios únicos en el mercado y te ayudáremos con todo para que puedas vender o rentar`
  }
]

const NewValuationSEO = ({ section }) => (
  <HeadSEO
    canonical={`valuation/new/${section}`}
    title={`Vende o renta con ${companyConfig.name}`}
    description={`${companyConfig.name} es la primera inmobiliaria digital de México, ofrecemos beneficios únicos en el mercado y te ayudáremos con todo para que puedas vender o rentar`}
    tags={SEO_TAGS}
  />
)

export default NewValuationSEO
