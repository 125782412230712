import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `Ingresa a tu cuenta | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content: `Ingresa a tu cuenta | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Si ya estás registrado, ingresa a tu cuenta para trabajar en la plataforma. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const LoginSEO = () => (
  <HeadSEO
    canonical="/ingresar"
    title={`Ingresa a tu cuenta | ${companyConfig.name}`}
    description={`Si ya estás registrado, ingresa a tu cuenta para trabajar en la plataforma. ${companyConfig.name}, alianza inmobiliaria.`}
    tags={SEO_TAGS}
  />
)

export default LoginSEO
