import React from 'react'
import Head from 'next/head'

const NonIndex = () => (
  <Head>
    <meta name="robots" content="follow, noarchive, noindex" />
  </Head>
)

export default NonIndex
