import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { useSrpParams } from 'app/hooks'

import { SRP } from 'components/SEO/utils'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `Encuentra tu próximo hogar | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content: `Encuentra tu próximo hogar | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Propiedades e inmuebles en venta y renta - Renta y ventas de casas y departamentos. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const BASE_DESCRIPTION = `Contacta a un asesor de ${companyConfig.name} y encuentra tu hogar.`

const SRPSEO = ({ numOfPages, countResults, limitPerPage }) => {
  const srpParams = useSrpParams()
  const finalParams = {
    ...srpParams,
    numOfPages,
    countResults,
    limitPerPage
  }

  const title = SRP.title(finalParams)
  const canonical = SRP.canonical(finalParams)
  const description = SRP.description(finalParams)

  return (
    <HeadSEO
      canonical={canonical}
      title={title}
      description={`${description} ${BASE_DESCRIPTION}`}
      tags={SEO_TAGS}
    />
  )
}

export default SRPSEO
