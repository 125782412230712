import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'

import { VIP } from 'components/SEO/utils'

const SEO_TAGS = [
  {
    mame: 'og:type',
    content: 'article'
  }
]

const VIPSEO = ({ property }) => {
  const title = VIP.title(property)
  const canonical = VIP.canonical(property)
  const description = VIP.description(property)
  const openGraphImage = VIP.openGraphImage(property)
  const jsonLdData = VIP.jsonLD(property)

  const author = VIP.author(property)
  const siteName = VIP.siteName(property)

  return (
    <HeadSEO
      title={title}
      canonical={canonical}
      description={description}
      openGraphImage={openGraphImage}
      tags={[...SEO_TAGS, author, siteName]}
      jsonLdData={jsonLdData}
    />
  )
}

export default VIPSEO
