import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Términos y condiciones | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content: `Términos y condiciones, sobre el uso de la plataforma ${companyConfig.name}`
  }
]

const TermsSEO = () => (
  <HeadSEO
    canonical="/terms"
    title={`Términos y condiciones | ${companyConfig.name}`}
    description={`Términos y condiciones, sobre el uso de la plataforma ${companyConfig.name}`}
    tags={SEO_TAGS}
  />
)

export default TermsSEO
