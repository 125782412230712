import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Aviso de privacidad | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Aviso de privacidad, sobre obtención de datos para el uso de la plataforma ${companyConfig.name}`
  }
]

const PrivacySEO = () => (
  <HeadSEO
    canonical="/politica-privacidad"
    title={`Aviso de privacidad | ${companyConfig.name}`}
    description={`Aviso de privacidad, sobre obtención de datos para el uso de la plataforma ${companyConfig.name}`}
    tags={SEO_TAGS}
  />
)

export default PrivacySEO
