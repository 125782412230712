import React from 'react'

import { TEAM } from 'components/SEO/utils'
import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Agentes inmobiliarios| ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Trabaja con los mejores agentes inmobiliarios. Los agentes de ${companyConfig.name} te ayudarán a encontrar la propiedad de tus sueños o te ayudarán a vender tu propiedad con el mejor conocimiento del mercado local. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const BASE_DESCRIPTION = `de los mejores agentes inmobiliarios de México. Los agentes de ${companyConfig.name} te ayudarán a encontrar la propiedad de tus sueños o te ayudarán a vender tu propiedad con el mejor conocimiento del mercado local. ${companyConfig.name}, alianza inmobiliaria.`

const TeamSEO = ({ query, numOfPages, countResults }) => {
  const params = {
    ...query,
    numOfPages,
    countResults
  }

  const title = TEAM.title(params)
  const canonical = TEAM.canonical(params)
  const description = TEAM.description(params)

  return (
    <HeadSEO
      canonical={canonical}
      title={title}
      description={`${description}${BASE_DESCRIPTION}`}
      tags={SEO_TAGS}
    />
  )
}

export default TeamSEO
