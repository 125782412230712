import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `La mejor comisión inmobiliaria del mercado en México | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content: `La mejor comisión inmobiliaria del mercado en México | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `La venta o renta de un inmueble es un proceso importante que en ${companyConfig.name} respetamos. Obtén la mejor comisión inmobiliaria del mercado. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const PromoteYourPropertiesSEO = () => (
  <HeadSEO
    canonical="/promueve-propiedades"
    title={`La mejor comisión inmobiliaria del mercado en México | ${companyConfig.name}`}
    description={`La mejor comisión inmobiliaria del mercado en México | ${companyConfig.name}`}
    tags={SEO_TAGS}
  />
)

export default PromoteYourPropertiesSEO
