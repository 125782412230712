import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content:
      `Precios membresías - Agentes Inmobiliarios, Inmuebles en venta y renta, valuaciones | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      'Conoce nuestros planes y formas de pago. Obten más beneficios con nuestro plan integral. Únete a nuestra Alianza, estamos a tus órdenes.'
  }
]

const PricesSEO = () => (
  <HeadSEO
    canonical="/precios"
    title={`Precios de membresías | ${companyConfig.name}`}
    description="Conoce nuestros planes y formas de pago. Obten más beneficios con nuestro plan integral. Únete a nuestra Alianza, estamos a tus órdenes."
    tags={SEO_TAGS}
  />
)

export default PricesSEO
