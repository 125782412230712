import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content:
      `Asesoramiento legal y fiscal para inmobiliarios en México | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content:
      `Asesoramiento legal y fiscal para inmobiliarios en México | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Ponemos al alcance de tu mano a abogados y fiscalistas para que puedas desarrollar tu carrera sin preocuparte. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const AdministrativeSupportSEO = () => (
  <HeadSEO
    canonical="/soporte-administrativo"
    title={`Asesoramiento legal y fiscal para inmobiliarios en México | ${companyConfig.name}`}
    description={`Ponemos al alcance de tu mano a abogados y fiscalistas para que puedas desarrollar tu carrera sin preocuparte. ${companyConfig.name}, alianza inmobiliaria.`}
    tags={SEO_TAGS}
  />
)

export default AdministrativeSupportSEO
