import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Contáctanos | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Contáctanos para resolver tus dudas acerca de la plataforma ${companyConfig.name}`
  }
]

const ContactSEO = () => (
  <HeadSEO
    canonical="/contactanos"
    title={`Contáctanos | ${companyConfig.name}`}
    description={`Contáctanos para resolver tus dudas acerca de la plataforma ${companyConfig.name}`}
    tags={SEO_TAGS}
  />
)

export default ContactSEO
