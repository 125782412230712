import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `Desarrolla relaciones fuertes con tus clientes | ${companyConfig.name}`
  },
  {
    itemProp: 'description',
    content:
      `${companyConfig.name} te da las herramientas necesarias para establecer excelentes relaciones con tus clientes. ${companyConfig.name}, alianza inmobiliaria.`
  },
  {
    name: 'og:title',
    content: `Desarrolla relaciones fuertes con tus clientes | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `${companyConfig.name} te da las herramientas necesarias para establecer excelentes relaciones con tus clientes. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const TransactionManagementSEO = () => (
  <HeadSEO
    canonical="/transaction-management"
    title={`Desarrolla relaciones fuertes con tus clientes | ${companyConfig.name}`}
    description={`Atrae más clientes de la mano de ${companyConfig.name}. Vende y renta más rápido tu cartera de propiedades gracias a nuestra red y alcance. ${companyConfig.name}, alianza inmobiliaria.`}
    tags={SEO_TAGS}
  />
)

export default TransactionManagementSEO
