import React, { Fragment } from 'react'
import Head from 'next/head'
import { image } from 'services'
import config from 'config'

import JsonLD from 'components/SEO/JsonLD'

const HOST_URL = config('HOST_URL')

const HeadSEO = ({
  jsonLdData,
  title,
  description,
  author,
  canonical,
  openGraphImage,
  tags = []
}) => {
  return (
    <Fragment>
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {author && <meta key="author" name="author" content={author} />}

      <link rel="canonical" href={`${HOST_URL}${canonical}`} />

      <meta
        property="og:image"
        content={openGraphImage || image('logo-blue.png')}
      />

      {tags.map((tag, index) => (
        <meta key={index} {...tag} />
      ))}

    </Head>
    {jsonLdData && <JsonLD data={jsonLdData} />}
    </Fragment>
  )
}

export default HeadSEO
