import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { dataOrganizationJsonLD } from 'components/SEO/data'
import { companyConfig } from 'globalConfigApp'

const HOME_DESCRIPTION =
  `La alianza inmobiliaria con agentes inmobiliarios incomparables. Ya seas comprador, vendedor o arrendatario, encuentra la mejor oferta en ${companyConfig.name}. En todo México, ${companyConfig.name} tiene los mejores inmobiliarios.`

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: 'Agentes Inmobiliarios, Inmuebles en venta y renta, valuaciones'
  },
  {
    name: 'og:title',
    content: 'Agentes Inmobiliarios, Inmuebles en venta y renta, valuaciones'
  },
  {
    name: 'og:description',
    content: HOME_DESCRIPTION
  }
]

const HomeSEO = () => (
  <HeadSEO
    canonical="/"
    title={`Inmuebles en venta y renta, Agentes Inmobiliarios, Valuaciones | ${companyConfig.name}`}
    description={HOME_DESCRIPTION}
    tags={SEO_TAGS}
    jsonLdData={dataOrganizationJsonLD}
  />
)

export default HomeSEO
