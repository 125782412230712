import React from 'react'
import { useParams } from 'app/hooks'

import HeadSEO from 'components/SEO/HeadSEO'

import { HELP, addCompany } from 'components/SEO/utils'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content: `Centro de ayuda | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Centro de ayuda sobre el uso de la plataforma ${companyConfig.name}, preguntas frecuentes y dudas`
  }
]

const HelpSEO = () => {
  const params = useParams()
  const { category, id } = params.filters

  const title = HELP.title(category, id)
  const canonical = HELP.canonical(category, id)

  return (
    <HeadSEO
      canonical={canonical}
      title={addCompany(title || 'Centro de ayuda')}
      description={`Centro de ayuda sobre el uso de la plataforma ${companyConfig.name}, preguntas frecuentes y dudas`}
      tags={SEO_TAGS}
    />
  )
}

export const getServerSideProps = context => ({ props: {} })

export default HelpSEO
