import React from 'react'

import { PROFILE } from 'components/SEO/utils'
import HeadSEO from 'components/SEO/HeadSEO'

const TeamProfileSEO = ({
  profile,
  query,
  numOfPages,
  countResults,
  limitPerPage,
  isPropertiesSection
}) => {
  const params = {
    ...query,
    numOfPages,
    countResults,
    limitPerPage,
    isPropertiesSection
  }

  const title = PROFILE.title(profile, params)
  const canonical = PROFILE.canonical(profile, params)
  const description = PROFILE.description(profile, params)
  const openGraphImage = PROFILE.openGraphImage(profile, params)

  return (
    <HeadSEO
      title={title}
      canonical={canonical}
      description={description}
      openGraphImage={openGraphImage}
      tags={[
        {
          name: 'og:title',
          content: title
        },
        {
          name: 'og:description',
          content: description
        }
      ]}
    />
  )
}

export default TeamProfileSEO
