import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    itemProp: 'name',
    content: `Conviertete en un experto inmobiliario en México | ${companyConfig.name}`
  },
  {
    name: 'og:title',
    content: `Conviertete en un experto inmobiliario en México | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `Capacitaciones y cursos para agentes inmobiliarios de la mano de ${companyConfig.name}. Aprende todo lo que necesitas saber para ser un experto inmobiliario. ${companyConfig.name}, alianza inmobiliaria.`
  }
]

const PersonalDevelopmentSEO = () => (
  <HeadSEO
    canonical="/desarrollo-personal"
    title={`Conviertete en un experto inmobiliario en México | ${companyConfig.name}`}
    description={`Capacitaciones y cursos para agentes inmobiliarios de la mano de ${companyConfig.name}. Aprende todo lo que necesitas saber para ser un experto inmobiliario. ${companyConfig.name}, alianza inmobiliaria.`}
    tags={SEO_TAGS}
  />
)

export default PersonalDevelopmentSEO
