import React from 'react'

import HeadSEO from './HeadSEO'
import HomePage from './pages'
import SRPPage from './pages/srp'
import VIPPage from './pages/vip'
import JoinPage from './pages/join'
import PricesSEO from './pages/prices'
import TeamSEO from './pages/team'
import TeamProfileSEO from './pages/teamProfile'
import PrivacySEO from './pages/privacy'
import TermsSEO from './pages/terms'
import HelpSEO from './pages/help'
import ContactSEO from './pages/contact'
import SignupSEO from './pages/signup'
import LoginSEO from './pages/login'
import NewValuationSEO from './pages/newValuation'
import AcquireMorePropertiesSEO from './pages/acquire-more-properties'
import BetterServeYourClientsSEO from './pages/better-serve-your-clients'
import TransactionManagementSEO from './pages/transaction-management'
import PersonalDevelopmentSEO from './pages/personal-development'
import AdministrativeSupportSEO from './pages/administrative-support'
import PromoteYourPropertiesSEO from './pages/promote-your-properties'
import NonIndex from './pages/NonIndex'

import baseTags from './baseTags'

const SEO = ({ type, ...rest }) => {
  if (type === 'HOME') {
    return <HomePage {...rest} />
  }
  if (type === 'SRP') {
    return <SRPPage {...rest} />
  }
  if (type === 'VIP') {
    return <VIPPage {...rest} />
  }
  if (type === 'NEW_VALUATION') {
    return <NewValuationSEO {...rest} />
  }
  if (type === 'JOIN') {
    return <JoinPage {...rest} />
  }
  if (type === 'PRICES') {
    return <PricesSEO {...rest} />
  }
  if (type === 'TEAM') {
    return <TeamSEO {...rest} />
  }
  if (type === 'TEAM_PROFILE') {
    return <TeamProfileSEO {...rest} />
  }
  if (type === 'PRIVACY') {
    return <PrivacySEO {...rest} />
  }
  if (type === 'TERMS') {
    return <TermsSEO {...rest} />
  }
  if (type === 'HELP') {
    return <HelpSEO {...rest} />
  }
  if (type === 'CONTACT') {
    return <ContactSEO {...rest} />
  }
  if (type === 'SIGNUP') {
    return <SignupSEO {...rest} />
  }
  if (type === 'LOGIN') {
    return <LoginSEO {...rest} />
  }
  if (type === 'ACQUIRE_MORE_PROPERTIES') {
    return <AcquireMorePropertiesSEO {...rest} />
  }
  if (type === 'BETTER_SERVE_YOUR_CLIENTS') {
    return <BetterServeYourClientsSEO {...rest} />
  }
  if (type === 'TRANSACTION_MANAGEMENT') {
    return <TransactionManagementSEO {...rest} />
  }
  if (type === 'PERSONAL_DEVELOPMENT') {
    return <PersonalDevelopmentSEO {...rest} />
  }
  if (type === 'ADMINISTRATIVE_SUPPORT') {
    return <AdministrativeSupportSEO {...rest} />
  }
  if (type === 'PROMOTE_YOUR_PROPERTIES') {
    return <PromoteYourPropertiesSEO {...rest} />
  }
  if (type === "NON_INDEX") {
    return <NonIndex />
  }

  return <HeadSEO {...baseTags} {...rest} />
}

export default SEO
