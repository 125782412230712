import React from 'react'

import HeadSEO from 'components/SEO/HeadSEO'
import { companyConfig } from 'globalConfigApp'

const SEO_TAGS = [
  {
    name: 'og:title',
    content:
      `Únete a los mejores agentes inmobiliarios - Agentes Inmobiliarios, Inmuebles en venta y renta, valuaciones | ${companyConfig.name}`
  },
  {
    name: 'og:description',
    content:
      `${companyConfig.name} está revolucionando el mercado inmobiliario de México. Capacitamos a los agentes inmobiliarios más exitosos del mercado por medio de tecnología. Facilitamos el proceso de búsqueda de un hogar en forma inteligente. Conoce más sobre nosotros.`
  }
]

const JoinSEO = () => (
  <HeadSEO
    canonical="/unete"
    title={`Únete a los mejores agentes inmobiliarios | ${companyConfig.name}`}
    description={`${companyConfig.name} está revolucionando el mercado inmobiliario de México. Capacitamos a los agentes inmobiliarios más exitosos del mercado por medio de tecnología. Facilitamos el proceso de búsqueda de un hogar en forma inteligente. Conoce más sobre nosotros.`}
    tags={SEO_TAGS}
  />
)

export default JoinSEO
